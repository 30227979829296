import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SoumarinWrapper from '../images/wrapper@2x.png'

const WrapperWrapper = styled.div`
  margin: 50px auto;
  width: 100%;
`

const SoumarinWrapperImage = styled.div`
  background-image: url(${SoumarinWrapper});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  border: 0 none;
  outline: none;
  padding: 24%;
}
`
const Text = styled.div`
  margin: 0 auto;
  max-width: 70%;
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
    
  @media only screen and (max-width : 375px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 90%;
  } 
`

const Description = styled(Text)`
  margin-top: 10px;
  padding: 20px 40px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  
  @media only screen and (max-width : 375px) {
    padding: 20px 20px;
    text-align: justify;
  } 
`


const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`soumarin`, `chocolate`, 'san francisco']} />
    <WrapperWrapper>
      <SoumarinWrapperImage />
    </WrapperWrapper>
    <Description>
      Soumarin hot chocolate submarines make the perfect hot chocolate! Simply heat up your favorite milk, drop in a submarine and stir. Made with
      couverture dark chocolate for a rich and delicious hot chocolate. Shipping starts Fall 2019.
    </Description>
  </Layout>
)

export default IndexPage
